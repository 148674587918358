export const RequestLoggingTransactionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'User',
    dataIndex: 'user_info',
    key: 'user_info'
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform'
  },
  {
    title: 'IP Address',
    dataIndex: 'requestIPAddress',
    key: 'requestIPAddress'
  },
  {
    title: 'Request Headers',
    dataIndex: 'requestHeaders',
    key: 'requestHeaders'
  },
  {
    title: 'Request Method',
    dataIndex: 'requestMethod',
    key: 'requestMethod'
  },
  {
    title: 'Request Url',
    dataIndex: 'requestUrl',
    key: 'requestUrl'
  },
  {
    title: 'Request Body',
    dataIndex: 'requestBody',
    key: 'requestBody'
  },
  {
    title: 'Response Status Code',
    dataIndex: 'responseStatusCode',
    key: 'responseStatusCode'
  },
  {
    title: 'Duration(ms)',
    dataIndex: 'durationInMilliseconds',
    key: 'durationInMilliseconds'
  },  
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
]
