import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, DatePicker, Flex, Popover, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DEFAULT_PAGE_SIZE, omitKeysFromObject, PAGINATION, renderJsonPreview, showTotalRecords } from '../../../const/utils'
import moment from 'moment'
import { TRANSACTION_DATE_FORMAT, transformUserIdFormat } from '../Transactions/Transactions.const'
import { fetchAllRewads } from '../../../store/rewards/actions'
import { RewardsListTableColumns } from './columns'

export const RewardsListScreen = () => {
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const dispatch = useDispatch()
  const [userRole, setUserRole] = useState('')
  const rewards = useSelector(state => state.rewards.rewards)
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)

  const hasSelected = selectedRowKeys.length > 0

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const { role } = userData?.user
    setUserRole(role)
  }, [])

  useEffect(() => {
    dispatch(fetchAllRewads())
  }, [])

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  return (
    <div className='bg-white shadow pb-3'>
      <PageHeader
        ghost={false}
        title={`Rewards List`}
      ></PageHeader>
      <Flex gap="middle">
        {/* <Flex gap="middle" vertical>
          <Flex gap="middle" className='h-[40px] ml-4'>
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </Flex>
        </Flex>
        <Flex gap="middle" className='mb-4'>
          <Button className='w-[200px] h-[40px]'
            // disabled={userRole != 'admin'} 
            onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
        </Flex> */}
      </Flex>
      <Table
        className='z-[0] relative overflow-auto'
        // rowSelection={rowSelection}
        columns={RewardsListTableColumns}
        dataSource={rewards?.map((i, it) => ({
          key: i?._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          name: i?.name,
          myImpact: <Tag color={i?.myImpact === true ? 'green' : 'yellow'}>{i?.myImpact ? 'Yes' : 'No'}</Tag>,
          // communityId: i?.communityId,
          points: i?.points,
          rewardId: i?.rewardId,
          details: <Popover content={renderJsonPreview(omitKeysFromObject(i, ['_id', 'createdAt', 'updatedAt']))} title="Details" >
            <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline'>View</p>
          </Popover>,
          createdAt: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          updatedAt: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT))
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          // hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: rewards?.length,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div >
  )
}