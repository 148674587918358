export const RewardsListTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'My Impact?',
    dataIndex: 'myImpact',
    key: 'myImpact'
  },
  {
    title: 'Points',
    dataIndex: 'points',
    key: 'points'
  },
  {
    title: 'Reward ID',
    dataIndex: 'rewardId',
    key: 'rewardId'
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details'
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  }
]
