import { FETCH_REQUEST_LOGGING_TRANSACTIONS } from './types'

const initialState = {
    requestLogs: []
}

const requestLoggingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUEST_LOGGING_TRANSACTIONS:
            return { ...state, requestLogs: action.payload }
        default:
            return state
    }
}

export default requestLoggingReducer