import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, SendOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Popconfirm, Tag, message, Modal, Form, Select } from 'antd'
import { useNavigate } from "react-router-dom"
import React, { useRef, useState, useEffect } from 'react'
import { fetchAllQuests, deleteQuestById } from '../../../store/quests/actions'
// import { fetchAllGoals } from '../../../store/goal/actions'
// import { fetchAllUsers } from '../../../store/users/action'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
// import Filter from '../../../components/Filter/Filter'
// import ServerApi from '../../../utility/ServerApi'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE, currentUserRole } from '../../../const/utils'
import { QUEST_WINDOW_MODE } from './Quests.const'
import { fetchAllLocations } from '../../../store/locations/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { SUSGAIN_ROLES } from '../../../const/roles'

export const Quests = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const communities = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)
    const quests = useSelector(state => state.quests.quests)
    // const users = useSelector(state => state.users.users)
    // const goals = useSelector(state => state.goals.goals)

    const [questsData, setQuestsData] = useState(quests?.result)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    // const [modelVisiblity, setModelVisiblity] = useState(false)
    // const [modelQuestId, setModelQuestId] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [communityId, setCommunityId] = useState()
    const [cityId, setCityId] = useState()
    const [wordEntered, setWordEntered] = useState()
    // const [selectedGoal, setSelectedGoal] = useState([])
    const [locationsData, setLocationsData] = useState(locations)

    const _currentUserRole = currentUserRole()

    useEffect(() => {
        dispatch(fetchAllQuests())
        dispatch(fetchAllLocations())
        dispatch(fetchAllCommunity({ current: 1, pageSize: 10000 }))
        // dispatch(fetchAllUsers())
        // dispatch(fetchAllGoals())
    }, [])

    useEffect(() => {
        setQuestsData(quests?.result)
    }, [quests])

    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm()
    //     setSearchText(selectedKeys[0])
    //     setSearchedColumn(dataIndex)
    // }

    useEffect(() => {
        const _param = {}
        if (wordEntered !== '') {
            _param['search'] = wordEntered?.toLowerCase()
        }
        if (communityId !== null && communityId !== '') {
            _param['communityId'] = communityId
        }
        if (cityId !== null && cityId !== '') {
            _param['cityId'] = cityId
        }
        dispatch(fetchAllQuests(_param))
    }, [communityId, cityId, wordEntered])

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)

        // if (searchWord === '') {
        //     dispatch(fetchAllQuests())
        //     return
        // }
        // dispatch(fetchAllQuests({ search: searchWord.toLowerCase() }))
    }

    const handleCommunityFilter = (id) => {
        setCommunityId(id)
    }

    const handleCityFilter = (id) => {
        setCityId(id)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    // const handleModelVisiblity = (name, id) => {
    //     setModelQuestId(name)
    //     setCommunityId(id)
    //     setModelVisiblity(true)
    // }

    // const onPostSucces = () => {
    //     setConfirmLoading(true)
    //     setTimeout(() => {
    //         form.resetFields()
    //         setModelVisiblity(false)
    //         setConfirmLoading(false)
    //     }, 1000)
    // }

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }


    // const getColumnSearchProps = (dataIndex) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //         <div
    //             style={{
    //                 padding: 8
    //             }}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: 'block'
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     className='mx-2 bg-[#207868]'
    //                     onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{
    //                         width: 90
    //                     }}
    //                 >
    //                     Search
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters)}
    //                     size="small"
    //                     style={{
    //                         width: 90
    //                     }}
    //                 >
    //                     Reset
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? '#1890ff' : undefined
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) => {
    //         if (record[dataIndex]) {
    //             if (dataIndex === 'status') {
    //                 return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
    //             } else {
    //                 return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //             }
    //         } else {
    //             return false
    //         }

    //     },
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100)
    //         }
    //     }
    // })

    const columns = [
        {
            title: 'No',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
            // ...getColumnSearchProps('name')
        },
        // {
        //     title: 'Goal',
        //     dataIndex: 'goal',
        //     key: 'goal',
        //     ...getColumnSearchProps('goal')
        // },
        {
            title: 'Community',
            dataIndex: 'community',
            key: 'community'
            // width: '20%',
            // ...getColumnSearchProps('community')
        },
        // {
        //     title: 'City',
        //     dataIndex: 'city',
        //     key: 'city'
        //     // width: '20%',
        //     // ...getColumnSearchProps('city')
        // },
        {
            title: 'Completion mode',
            dataIndex: 'windowMode',
            key: 'windowMode'
            // ...getColumnSearchProps('windowMode')
        },
        {
            title: 'Total Activities',
            dataIndex: 'totalActivities',
            key: 'totalActivities'
            // ...getColumnSearchProps('totalActivities')
        },
        {
            title: 'Target Point',
            dataIndex: 'point',
            key: 'point'
            // ...getColumnSearchProps('point')
        },
        {
            title: 'Reward',
            dataIndex: 'reward',
            key: 'reward'
            // ...getColumnSearchProps('reward')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Share Link',
            dataIndex: 'shareUrl',
            key: 'shareUrl'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '120px'
        }
    ]

    const deleteQuest = (id) => {
        dispatch(deleteQuestById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    // const handleModalCancel = () => {
    //     setModelVisiblity(false)
    // }

    // const sendCommunityMessage = (values) => {
    //     const data = {
    //         communityId,
    //         sendto: values.email || values.userId
    //         // userId: values.userId,
    //         // email: values.email
    //     }
    //     console.log(data)
    //     onPostSucces()
    // }

    // const handleGoalFilter = (goal, habits) => {
    //     if (goal === undefined) {
    //         setQuestsData(quests?.result)
    //         setSelectedGoal([])
    //     } else {
    //         setSelectedGoal(habits?.result)

    //     }
    // }

    // const handleStatusFilter = (status) => {
    //     if (status === 'ALL') {
    //         setQuestsData(quests?.result)
    //     } else {
    //         ServerApi().get(`quests?published=${status}`)
    //             .then(res => {
    //                 setQuestsData(res.data.result)
    //             })
    //             .catch(e => {
    //                 console.log(e)
    //             })
    //     }
    // }

    const handleTableChange = (pagination) => {
        dispatch(fetchAllQuests(pagination))
    }

    const _translateWindowMode = (ele) => {
        const values = Object.values(QUEST_WINDOW_MODE)
        const _value = ele.windowMode || QUEST_WINDOW_MODE.TIME_PERIOD.value
        const _mode = values.find(i => i.value == _value)
        if (_mode.value == QUEST_WINDOW_MODE.TIME_PERIOD.value) {
            return `${_mode.label} (${moment(ele?.timePeriod?.from).format(BE_SHORT_DATE_FORMAT)} to ${moment(ele?.timePeriod?.to).format(BE_SHORT_DATE_FORMAT)})`
        } else {
            return `${_mode.label} (${ele?.completeWithin} days)`
        }
    }

    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Manage Quests"
                extra={[<Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868]" onClick={() => navigate('/quests/add')} >New Quest</Button>]}
            ></PageHeader>
            <div className='flex items-center mr-5 mb-5'>
                <Input
                    className='w-[300px] h-[40px] ml-4 mr-2'
                    placeholder='Search by name'
                    value={wordEntered}
                    onChange={handleSearch}
                    prefix={<SearchOutlined />}
                />
                <div>
                    {/* <Select
                        bordered={false}
                        className='w-[180px] h-[40px] ml-4 mr-2 border-[1px] border-mainThemeColorBorder'
                        placeholder='Filter by City'
                        optionFilterProp='label'
                        options={locationsData?.map(i => ({ label: i.name, value: i._id }))}
                        onChange={handleCityFilter}
                        allowClear
                    /> */}
                    {_currentUserRole == SUSGAIN_ROLES.ADMIN.role &&
                        <Select
                            // bordered={false}
                            className='w-[220px] h-[40px] ml-4 mr-2'
                            placeholder='Filter by Community'
                            optionFilterProp='label'
                            options={communities.result?.map(i => ({ label: i.name, value: i._id }))}
                            onChange={handleCommunityFilter}
                            allowClear
                        />
                    }
                    {/* <Filter type={'Goals'} /> */}
                    {/* <Filter type={'Habits'} /> */}
                    {/* <Filter type={'status'} currentPage={'quests'} /> */}
                    {/* <Select
                        bordered={false}
                        className='w-[180px] h-[40px] mx-4 mb-5 border-[1px] border-mainThemeColorBorder'
                        placeholder='Filter by Goal'
                        optionFilterProp='label'
                        options={goals?.map(i => ({ label: i.name, value: i.name, habits: i.habits }))}
                        onChange={handleGoalFilter}
                        allowClear
                    />
                    <Select
                        bordered={false}
                        className='w-[180px] h-[40px] mx-4 mb-5 border-[1px] border-mainThemeColorBorder'
                        placeholder='Filter By Habit'
                        optionFilterProp='label'
                        options={selectedGoal.habits?.map(i => ({ label: i.name, value: i.name }))}
                        // onChange={handleCommunityFilter}
                        allowClear
                    /> */}
                    {/* <Select
                        bordered={false}
                        className='w-[180px] h-[40px] mx-4 border-[1px] border-mainThemeColorBorder'
                        placeholder='Filter by Status'
                        onChange={handleStatusFilter}
                    >
                        <Select.Option value='ALL'>ALL</Select.Option>
                        <Select.Option value='true'>Published</Select.Option>
                        <Select.Option value='false'>Not Published</Select.Option>
                    </Select> */}
                </div>
                {/* <div className='flex'>
                    <Button className='mx-2 h-[40px] border-[#207868] text-[#207868]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                </div> */}
            </div>
            <Table
                className='z-[0] relative overflow-auto'
                columns={columns}
                dataSource={questsData?.map((i, it) => ({
                    key: i._id,
                    srno: ++it,
                    name: i.name,
                    city: i.cities?.map(k => k.name).join(", "),
                    // goal: i.goal,
                    community: i?.communityIds?.[0]?.name,
                    windowMode: _translateWindowMode(i),
                    // timePeriod: `${moment(i.timePeriod.from).format(BE_SHORT_DATE_FORMAT)} to ${moment(i.timePeriod.to).format(BE_SHORT_DATE_FORMAT)}`,
                    totalActivities: `${i?.activityIds?.length}`,
                    point: i?.points,
                    reward: i?.rewardId?.name,
                    validation: i?.validationMethod,
                    status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                    shareUrl: i.qrCode && (
                        <>
                            {i.qrCode.qr_code &&
                                <img
                                    className="w-20"
                                    src={`${i.qrCode.qr_code}`}
                                />
                            }
                            <CopyToClipboard text={i.qrCode.share_url}>
                                <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
                            </CopyToClipboard>
                        </>
                    ),
                    createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
                    action:
                        <div style={{ minWidth: '90px' }}>
                            {/* <Tooltip title="Send">
                                <Button style={{ marginRight: "8px" }}
                                    onClick={() => handleModelVisiblity(i.name, i._id)}
                                    size="small" type="ghost" shape="circle" icon={<SendOutlined />} />
                            </Tooltip> */}
                            <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/quests/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure?"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => deleteQuest(i._id)}
                                onCancel={() => null}
                                okText="Yes"
                                okType="danger"
                                placement="topLeft"
                                cancelText="Cancel"
                            >
                                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>

                        </div>
                }))}
                pagination={{
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: quests?.totalCount,
                    defaultPageSize: DEFAULT_PAGE_SIZE
                }}
                onChange={handleTableChange}
            />
            {/* <Modal
                title={modelQuestId}
                visible={modelVisiblity}
                setModelVisiblity={setModelVisiblity}
                footer={""}
                closable={true}
                onCancel={handleModalCancel}
            >
                <Form
                    form={form}
                    name='questMessage'
                    onFinish={sendCommunityMessage}
                    labelCol={{
                        span: 4
                    }}
                >
                    <Form.Item
                        labelAlign="left"
                        label='User'
                        name='userId'
                    // rules={[{ required: true, message: 'Please Select atleast 1 user' }]}
                    >
                        <Select
                            mode='multiple'
                            options={users.result?.map(i => ({ label: i?.name, value: i?._id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        labelAlign="left"
                        label='E-mail'
                        name='email'
                    // rules={[{ type: 'email', message: 'Inalid Email' }, { required: true, message: 'Please input your E-mail!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="text-center mt-4">
                        <Button className="bg-[#207868] px-6" loading={confirmLoading} type="primary" htmlType="submit">
                            Send
                        </Button>
                    </div>
                </Form>
            </Modal> */}
        </div>
    )
}